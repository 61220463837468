import { Box, Typography } from "@mui/material";

import { textStyle, textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

import { ReactComponent as NewHowItContact1Icon } from "../../../assets/call.svg";
import { ReactComponent as NewHowItContact2Icon } from "../../../assets/sms.svg";
import { ReactComponent as NewHowItContact3Icon } from "../../../assets/location.svg";

const ContactDetails = ({ isMobile, isIpad }: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 10,
        width: "100%",
        marginBottom: isMobile || isIpad ? "50px" : "0",
        marginTop: isMobile || isIpad ? "0" : "60px",
      }}
    >
      {/* Section heading with new styles */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: isMobile || isIpad ? "95%" : "100%",
          height: "120px",
          textAlign: isMobile || isIpad ? "center " : isRtl ? "right" : "left", // Align text based on language
        }}
      >
        <Typography
          className={isRtl ? "rtl-text" : ""}
          variant="h6"
          sx={{
            ...textStyle,
            fontSize: "18px",
            color: "#000000",
            fontWeight: 400,
            lineHeight: "150%",
          }}
        >
          {t("contactUs")}
        </Typography>

        <Typography
          className={isRtl ? "rtl-text" : ""}
          variant="h4"
          sx={{
            ...textStyle,
            fontSize: isMobile ? "24px" : "32px",
            color: "#1C1B13",
            fontWeight: 700,
            lineHeight: "130%",
          }}
        >
          {t("getInTouchInContact")}
        </Typography>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          variant="h6"
          sx={{
            ...textStyleRegular,
            fontSize: "20px",
            color: "#1C1B13",
            fontWeight: 400,
            lineHeight: "150%",
          }}
        >
          {t("contactDescription")}
        </Typography>
      </Box>

      {/* Contact details with new styles */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "95%" : "100%",
          height: "244px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "53px",
            gap: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              flexDirection: isRtl ? "row-reverse" : "row",
            }}
          >
            <NewHowItContact1Icon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                textAlign: isRtl ? "right" : "left", // Align text based on language
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  fontSize: "16px",
                  color: "#1C1B13",
                  fontWeight: 400,
                  lineHeight: "150%",
                }}
              >
                {t("callUs")}
              </Typography>
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body2"
                sx={{
                  ...textStyle,
                  fontSize: "16px",
                  color: "#1C1B13",
                  fontWeight: 500,
                  lineHeight: "130%",
                }}
              >
                9200033232
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              flexDirection: isRtl ? "row-reverse" : "row",
            }}
          >
            <NewHowItContact2Icon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                textAlign: isRtl ? "right" : "left", // Align text based on language
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "53px",
                }}
              >
                {/* Email */}
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="body1"
                  sx={{
                    ...textStyleRegular,
                    fontSize: "16px",
                    color: "#1C1B13",
                    fontWeight: 400,
                    lineHeight: "150%",
                  }}
                >
                  {t("emailAddress")}
                </Typography>
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="body2"
                  sx={{
                    ...textStyle,
                    fontSize: "16px",
                    color: "#1C1B13",
                    fontWeight: 500,
                    lineHeight: "130%",
                  }}
                >
                  support@getsak.com
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              flexDirection: isRtl ? "row-reverse" : "row",
            }}
          >
            <NewHowItContact3Icon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "74px",
                textAlign: isRtl ? "right" : "left", // Align text based on language
              }}
            >
              {/* Location */}
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  fontSize: "16px",
                  color: "#1C1B13",
                  fontWeight: 400,
                  lineHeight: "150%",
                }}
              >
                {t("location")}
              </Typography>
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body2"
                sx={{
                  ...textStyle,
                  fontSize: "16px",
                  color: "#1C1B13",
                  fontWeight: 500,
                  lineHeight: "130%",
                }}
              >
                {t("locationDetails")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactDetails;
