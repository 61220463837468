import LandingPageBasicInfo from "./landing-page-basic-info";
import IconLocation from "../../images/icons/icon-location.png";
import Iconwafi from "../../images/icons/icon-wafi.png";
import DocumentTitle from "../actions/DocumentTitle";

type Props = {
  Landingpage?: any;
};

const LandingPageContent = (props: Props) => {
  const { Landingpage } = props;
  DocumentTitle(`${Landingpage?.Name_ar}`);
  

  return (
    <div className="landing-page__content">
      <div className="landing-page__content__top">


        
      {Landingpage.property_types.length > 0 && (
          <div className="build-status">
            {Landingpage.property_types.map((property: any, index: any) => (
              <span className="text">
                {property.Name_ar}{" "}
                {index < Landingpage.property_types.length - 1 && "،"}
              </span>
            ))}
          </div>
        )}
        
        {Landingpage?.is_selling_on_map === true && (
          <div className="build-status">
            <span className="text">بيع على الخارطة</span>
          </div>
        )}
        <h3 className="title">
          {Landingpage?.landing_page?.ProjectName}
        </h3>
        <span className="location">
          <img src={IconLocation} alt="" className="icon-location" />
          <span className="text">
            {Landingpage?.City?.Name_ar} / {Landingpage?.Neighbourhood?.Name_ar}
          </span>
        </span>
      </div>
      {Landingpage.project_details.length > 0 && (
        <LandingPageBasicInfo projectDetails={Landingpage.project_details} />
      )}
      {Landingpage.Wafi_Agreement_Number && (
        <div className="landing-page__content__brind">
          <img src={Iconwafi} alt="" />
          <span className="text">مشروع مرخص من قبل وافي</span>
        </div>
      )}
    </div>
  );
};

export default LandingPageContent;
