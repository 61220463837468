import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";

import sakLogo3X from "../../assets/SakLogo3X.png";
import googlePlayLigth3X from "../../assets/GooglePlayLigth3X.png";
import appleStoreLigth3X from "../../assets/AppleStoreLigth3x.png";
import patternImage from "../../assets/Patterns.png";

import Button from "@mui/material/Button";
import { useTheme, useMediaQuery, ButtonBase } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { textStyle } from "../../shared/styles";
import { useLanguageTransition } from "../../LanguageTransitionContext";

import { ReactComponent as NewFacebookIcon } from "../../assets/FacebookSVG.svg";
import { ReactComponent as NewXIcon } from "../../assets/XSVG3X.svg";
import { ReactComponent as NewInstgramIcon } from "../../assets/InstagramSVG.svg";
import { ReactComponent as NewLinkedInIcon } from "../../assets/linkedInSVG.svg";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const isRtl = i18n.language === "ar";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isTransitioning } = useLanguageTransition();
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const navigate = useNavigate();

  const handleClick = (route: string) => {
    navigate(route); // Replace with your route
  };

  return (
    <div
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          bgcolor: "#000000",
          color: "#F0F0EF",
          width: "100%",
          mt: "auto",
          direction: isRtl ? "rtl" : "ltr", // Set direction based on language
          backgroundImage: `url(${patternImage})`, // Add your pattern image path here
          backgroundRepeat: "repeat", // This will repeat your pattern image
          backgroundPosition: "top center", // Adjust as needed
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={8}>
            <Grid item xs={isIpad ? 6 : 12} md={6} spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                  gap: 2,
                }}
              >
                <Box
                  component="img"
                  src={sakLogo3X}
                  alt="Logo"
                  sx={{ width: 80, height: 80, borderRadius: "13.3333px" }}
                />
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="body2"
                  sx={{
                    ...textStyle,
                    fontSize: "14px",
                    lineHeight: "150%",
                    margin: isMobile ? "0px" : "20px 0px",
                  }}
                >
                  {t("footerDescription")}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={isIpad ? 6 : 12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: isMobile
                  ? "column"
                  : isRtl
                  ? "row-reverse"
                  : "row",
                marginBottom: isMobile ? "20px" : "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  order: isMobile ? 1 : isRtl ? 2 : 1, // Change order on mobile
                  alignItems: "flex-start", // Align items to the end for RTL and start for LTR
                  marginBottom: isMobile ? "40px" : "0px",
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  sx={{
                    ...textStyle,
                    fontSize: "18px",
                    color: "inherit",
                    textDecoration: "none",
                    marginLeft: isMobile ? "15px" : isRtl ? "0" : "65px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {t("helpCenter")}
                </Typography>

                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  // sx={{ marginLeft: isMobile ? "0" : "20px" }}
                >
                  {/* First Row */}
                  <Grid item xs={10} sm={10} md={10} lg={7}>
                    <ButtonBase
                      onClick={() => handleClick("/privacy-policy-ar")}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        "&:hover": { color: "yellow" },
                      }}
                    >
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        sx={{
                          ...textStyle,
                          fontSize: "12px",
                          color: "inherit",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t("privacyPolicy")}
                        <ArrowForwardIosIcon
                          sx={{
                            fontSize: "inherit",
                            transform: isRtl ? "rotate(180deg)" : "none",
                            marginRight: isRtl ? "10px" : "0px",
                            marginLeft: isRtl ? "0px" : "10px",
                            width: "10px",
                            height: "10px",
                          }}
                        />
                      </Typography>
                    </ButtonBase>
                  </Grid>

                  {/* Second Row */}
                  <Grid item xs={10} sm={10} md={10} lg={7}>
                    <ButtonBase
                      onClick={() => handleClick("/about-sak")}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        "&:hover": { color: "yellow" },
                      }}
                    >
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        sx={{
                          ...textStyle,
                          fontSize: "12px",
                          color: "inherit",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t("aboutSak")}
                        <ArrowForwardIosIcon
                          sx={{
                            fontSize: "inherit",
                            transform: isRtl ? "rotate(180deg)" : "none",
                            marginRight: isRtl ? "10px" : "0px",
                            marginLeft: isRtl ? "0px" : "10px",
                            width: "10px",
                            height: "10px",
                          }}
                        />
                      </Typography>
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isMobile ? "center" : "flex-start",
                  order: isMobile ? 2 : isRtl ? 1 : 2, // Change order on mobile
                }}
              >
                <Box
                  sx={{
                    marginLeft: isRtl ? "0" : "10px",
                    marginRight: isRtl ? "10px" : "0",
                    ...textStyle,
                    marginBottom: isMobile ? "10px" : "0px",
                  }}
                >
                  {t("downloadApp")}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "column",
                  }}
                >
                  <Button
                    component="a"
                    href="https://app.adjust.com/17q1d5fo"
                    target="_blank"
                  >
                    <img
                      src={googlePlayLigth3X}
                      alt="Get it on Google Play"
                      style={{ width: isMobile ? "120px" : "150px" }}
                    />
                  </Button>
                  <Button
                    component="a"
                    href="https://app.adjust.com/17b556zr"
                    target="_blank"
                  >
                    <img
                      src={appleStoreLigth3X}
                      alt="Download on the App Store"
                      style={{ width: isMobile ? "122px" : "150px" }}
                    />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              borderTop: "1px solid #F0F0EF",
              padding: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isMobile
                ? "column"
                : isRtl
                ? "row-reverse"
                : "row",
            }}
          >
            <Typography
              className={isRtl ? "rtl-text" : ""}
              variant="body2"
              sx={{
                ...textStyle,
                order: isMobile ? 2 : isRtl ? 2 : 1, // Change order on mobile
              }}
            >
              © {new Date().getFullYear()} {t("allRightsReserved")}
            </Typography>
            <Box
              sx={{
                display: "flex", // Ensures the icons are aligned in a row
                "& .MuiIconButton-root": {
                  margin: "0 0px", // Adjusts the horizontal margin between the icons
                },
                order: isMobile ? 1 : isRtl ? 1 : 2, // Change order on mobile
              }}
            >
              <IconButton
                color="inherit"
                href="https://twitter.com/Getsakcom"
                target="_blank"
              >
                <NewXIcon
                  style={{
                    width: isMobile ? "40px" : "45px",
                  }}
                />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://instagram.com/getsakcom0"
                target="_blank"
              >
                <NewInstgramIcon
                  style={{
                    width: isMobile ? "40px" : "45px",
                  }}
                />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.linkedin.com/company/getsakcom/"
                target="_blank"
              >
                <NewLinkedInIcon
                  style={{
                    width: isMobile ? "40px" : "45px",
                  }}
                />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.facebook.com/profile.php?id=100083397186110"
                target="_blank"
              >
                <NewFacebookIcon
                  style={{
                    width: isMobile ? "40px" : "45px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Footer;
