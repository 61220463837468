import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";

import FAQItem from "./FaqItem";
import StyledBox from "./StyledBox";
import StyledButton from "./StyledButton";
import { textStyle, textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";
import React from "react";
import { baseURL } from "../../../shared/constants";
import axios from "axios";

const FAQSection = ({ id }: any) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const { isTransitioning } = useLanguageTransition();

  const [faqs, setFaqs] = React.useState<any>([]);

  const getFaqs = async () => {
    const headers = {
      "X-Api-Version": "v2",
      "Accept-Language": i18n.language, // Assuming i18n is defined earlier
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${baseURL}/contact-us/faq`, {
        headers,
      });
      setFaqs(response.data.data.data);
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getFaqs();
  }, [i18n.language]); // Add i18n.language as a dependency to refetch FAQs on language change

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
        marginBottom: "30px",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            className={isRtl ? "rtl-text" : ""}
            sx={{
              ...textStyle,
              textAlign: "center",
              color: "#6263AB",
              fontWeight: 700,
              mb: "20px",
            }}
          >
            {t("faqTitle")}
          </Typography>
          <Typography
            className={isRtl ? "rtl-text" : ""}
            variant="h5"
            sx={{
              ...textStyle,
              mb: "20px",
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            {t("faqSubtitle")}
          </Typography>
          <Typography
            className={isRtl ? "rtl-text" : ""}
            sx={{ ...textStyleRegular, mb: "10px", textAlign: "center" }}
          >
            {t("faqDescription")}
          </Typography>
        </Box>
        <Grid
          container
          spacing={isMobile ? 2 : 4}
          direction={isRtl ? "row-reverse" : "row"}
        >
          <Grid item xs={12} md={isIpad ? 12 : 8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {faqs?.length === 0 && (
                  <>
                    <FAQItem
                      title={t("faqItem1Title")}
                      content={t("faqItem1Content")}
                    />
                    <FAQItem
                      title={t("faqItem2Title")}
                      content={t("faqItem2Content")}
                    />
                    <FAQItem
                      title={t("faqItem3Title")}
                      content={t("faqItem3Content")}
                    />
                    <FAQItem
                      title={t("faqItem4Title")}
                      content={t("faqItem4Content")}
                    />
                    <FAQItem
                      title={t("faqItem5Title")}
                      content={t("faqItem5Content")}
                    />
                    <FAQItem
                      title={t("faqItem6Title")}
                      content={t("faqItem6Content")}
                    />
                  </>
                )}
                {faqs?.slice(0, 6).map((faq: any, index: number) => (
                  <FAQItem
                    key={index}
                    title={
                      i18n.language === "ar"
                        ? faq.attributes.Question_ar
                        : faq.attributes.Question_en
                    }
                    content={
                      i18n.language === "ar"
                        ? faq.attributes.Answer_ar
                        : faq.attributes.Answer_en
                    }
                  />
                ))}
                {faqs?.length > 6 && <StyledButton />}{" "}
                {/* Conditionally render the button */}
              </Box>
            </Box>
          </Grid>
          {!isMobile && !isIpad && (
            <Grid item xs={12} md={4}>
              <StyledBox />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default FAQSection;
