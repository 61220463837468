import {
  Box,
  Typography,
  Paper,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";

import MagediaImage3X from "../../../assets/Magedia3X.png";
import ElmImage3X from "../../../assets/Elm3X.png";
import AhcImage3X from "../../../assets/AHC3XImage.png";
import AwaedImage3X from "../../../assets/Awaed3X.png";
import GopayImage3X from "../../../assets/GoPay3X.png";

import { textStyle } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";
import { useRef } from "react";

const PartnerLogos = [
  MagediaImage3X,
  // ElmImage3X,
  AhcImage3X,
  // AwaedImage3X,
  // GopayImage3X,
];

const PartnerSectionComponent = ({ id }: any) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const { isTransitioning } = useLanguageTransition();

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
      }}
    >
      <Container maxWidth="lg">
        <Paper
          elevation={0}
          sx={{ pt: 4, marginTop: "20px", overflow: "hidden" }}
        >
          <Typography
            className={isRtl ? "rtl-text" : ""}
            variant="h5"
            gutterBottom
            align="center"
            sx={{
              ...textStyle,
              fontWeight: "bold",
              mb: isMobile ? 2 : 4, // Adjust bottom margin for spacing on mobile
              direction: isRtl ? "rtl" : "ltr", // Adjust direction based on language
            }}
          >
            {t("ourSuccessPartners")}
          </Typography>
          <Box
            ref={scrollRef}
            sx={{
              display: "flex",
              overflowX: "auto",
              overflowY: "hidden",
              "&::-webkit-scrollbar": {
                display: "none", // This hides the scrollbar in WebKit browsers like Chrome and Safari
              },
              "-ms-overflow-style": "none", // This hides the scrollbar in IE and Edge
              "scrollbar-width": "none", // This hides the scrollbar in Firefox
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {PartnerLogos.map((logo, index) => (
              <Box
                key={index}
                component="img"
                src={logo}
                alt={`Partner ${index + 1}`}
                sx={{
                  objectFit: "contain",
                  width: isMobile ? "30%" : "17%", // Adjust as needed
                  mx: 2, // Margin for each image
                  opacity: 0.5, // Set initial low opacity
                  transform: "scale(1)", // Initial scale
                  transition: "opacity 0.3s ease, transform 0.3s ease", // Smooth transition for opacity and transform
                  "&:hover": {
                    opacity: 1, // Full opacity on hover
                    transform: "scale(1.1)", // Scale up image on hover
                    cursor: "pointer",
                  },
                }}
              />
            ))}
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default PartnerSectionComponent;
