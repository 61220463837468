import axios from "axios";


export const GetData = async (Id, setData, setLoading) => {
  const options = {
    method: "get",
    url: `https://cms-staging.getsak.com/api/project/slug?slug_name=${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
    },
  };
  await axios(options)
    .then(function (response) {
      if (response.status === 200) {
        setData(response.data);
        setLoading(true);
      } else {
        //window.location.pathname = `/not-found`;
      }
    })
    .catch(function (error) {
     // window.location.pathname = `/not-found`;
    });
};


export const SendData = async (data, phone, slug, setErrorMsg, setLoading) => {
  const myHeaders = new Headers();
  myHeaders.append("X-Api-Version", "v3");
  myHeaders.append("Accept-Language", "en");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("API-KEY", "xUgFOmJHkvuY_VqZsQcjpg");

  const raw = JSON.stringify({
    "full_name": data.username,
    "phone_number": `966${phone.replace(/\s/g, '')}`,
    "email": data.email,
    "project_slug_name": slug,
    "source": "Landingpage"
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  fetch("https://api-staging.getsak.com/api/add-project-interest/", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      setLoading(true)
      if (result.response_code === 200) {
        window.location.pathname = `/thank-you`;
      } else {
        setErrorMsg(result.response_message)
        console.log("error");
      }
    })
    .catch((error) => {
      console.log("error");
    });
};
